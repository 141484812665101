<template>
	<div id="comfire">
		<Else></Else>
		<div class="comfire-box">
			<div class="comfire-title">品牌监控</div>
			<div class="comfire-middle">
				<div class="btns">
					<el-button size="small" type="success" @click="isShow = true"><i class="el-icon-plus el-icon--right"></i>新增</el-button>
				</div>
				<el-table :data="list" ref="refTable" :element-loading-text="elementLoadingText" border height="80%" @row-click="rowClisk"
				 v-loading="loading">
					<el-table-column show-overflow-tooltip prop="sqr" label="申请人"></el-table-column>
					<el-table-column show-overflow-tooltip prop="pinpai" label="品牌"></el-table-column>
					<el-table-column show-overflow-tooltip label="平台">
						<template slot-scope="scope">
							<div>{{getZhanDian(scope.row.zhandian)}}</div>
						</template>
					</el-table-column>
					<el-table-column show-overflow-tooltip label="开始时间">
						<template slot-scope="scope">
							<div v-if="scope.row.beginDate">{{scope.row.beginDate.slice(0,10)}}</div>
						</template>
					</el-table-column>
					<el-table-column show-overflow-tooltip label="结束时间">
						<template slot-scope="scope">
							<div v-if="scope.row.endDate">{{scope.row.endDate.slice(0,10)}}</div>
						</template>
					</el-table-column>
					</el-table-column>
					<el-table-column label="操作" width="240">
						<template slot-scope="scope">
							<el-button type="text" @click.stop="rowClisk(scope.row)">展开</el-button>
							<el-button type="text" @click.stop="handleEdit(scope.row)">编辑</el-button>
							<el-button type="text" @click.stop="handleDelete(scope.row, scope.$index)">删除</el-button>
						</template>
					</el-table-column>
					<!-- 二级表格 -->
					<el-table-column type="expand" width="1">
						<template slot-scope="props">
							<div style="margin: 0 30px;">
								<el-button type="success" size="small" style="margin-bottom: 10px;" @click="isShow2 = true"><i
									 class="el-icon-plus el-icon--right"></i>新增</el-button>
								<el-table :data="list2" height="250" border style="width: 100%;" v-loading="loading2">
									<el-table-column property="shop" label="店铺" width="150"></el-table-column>
									<el-table-column property="goods" label="商品" width="200"></el-table-column>
									<el-table-column property="price" label="价格"></el-table-column>
									<el-table-column property="remark" label="备注"></el-table-column>
									<el-table-column label="操作" width="100">
										<template slot-scope="scope">
											<el-button type="text" @click.stop="handleDelete2(scope.row, scope.$index)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination style="text-align: center;margin-top: 10px;" background @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
								 :current-page="page2" :page-sizes="[10, 30, 50, 100]" :page-size="size2" layout="total, sizes, prev, pager, next, jumper"
								 :total="total2">
								</el-pagination>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="text-align: center;margin-top: 10px;" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="page" :page-sizes="[10, 30, 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
				<el-dialog :visible.sync="isShow" width="30%">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="申请人">
							<el-input v-model="form.sqr"></el-input>
						</el-form-item>
						<el-form-item label="品牌">
							<el-input v-model="form.pinpai"></el-input>
						</el-form-item>
						<el-form-item label="平台">
							<el-select v-model="form.zhandian" placeholder="请选择">
								<el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<!-- <el-input v-model="form.zhandian"></el-input> -->
						</el-form-item>
						<el-form-item label="开始时间">
							<div class="block">
								 <!-- value-format="YYYY-MM-DD" -->
								<el-date-picker @change="beginChange" style="width: 100%;" v-model="form.beginDate" type="date" placeholder="开始时间">
								</el-date-picker>
							</div>
						</el-form-item>
						<el-form-item label="结束时间">
							<div class="block">
								<el-date-picker @change="endChange" style="width: 100%;" v-model="form.endDate" type="date" placeholder="结束时间">
								</el-date-picker>
							</div>
						</el-form-item>
					</el-form>
					<span slot="footer" class="dialog-footer">
						<el-button @click="isShow = false">取 消</el-button>
						<el-button type="success" @click="addHandle" v-if="!form.id">添 加</el-button>
						<el-button type="success" @click="addHandle" v-else>修 改</el-button>
					</span>
				</el-dialog>
				<el-dialog :visible.sync="isShow2" width="40%" top="5vh">
					<el-form ref="form2" :model="form2" label-width="80px">
						<el-form-item label="店铺" prop="shop" :rules="{ required: true, message: '店铺名不能为空', trigger: 'blur' }">
							<el-input v-model="form2.shop"></el-input>
						</el-form-item>
						<div v-for="(item, index) in form2.goods">
							<el-form-item :label="'商品' + (index+1)" :key="index" :prop="'goods.' + index + '.goods'" :rules="{ required: true, message: '商品名不能为空', trigger: 'blur' }"
							 class="good">
								<el-input v-model="item.goods"></el-input>
								<el-button style="height: 36px;margin-left: 20px;" v-if="index!=0" @click.prevent="removeDomain(item)">删除</el-button>
							</el-form-item>
							<el-form-item label="价格" :key="'p' + index" :prop="'goods.' + index + '.price'" :rules="{ required: true, message: '价格不能为空', trigger: 'blur' }">
								<el-input v-model="item.price"></el-input>
							</el-form-item>
							<el-form-item label="备注" :key="'r' + index" :prop="'goods.' + index + '.remark'">
								<el-input type="textarea" v-model="item.remark" placeholder="备注"></el-input>
							</el-form-item>
						</div>
					</el-form>
					<span slot="footer" class="dialog-footer">
						<el-button @click="isShow2 = false">取 消</el-button>
						<el-button @click="addDomain">新增商品</el-button>
						<el-button type="success" @click="addHandle2">确 定</el-button>
					</span>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		addOrUpdate,
		getWatchList,
		watchDelete,
		showDetail,
		deleteDetail,
		addOrUpdateDetail
	} from "@/api/monitoring";
	import Else from '../ui/else.vue';
	import * as _ from '@/utils/tool'
	export default {
		name: 'comfire',
		data() {
			return {
				inputVisible: false,
				inputValue: '',
				isShow: false,
				list: [],
				fullLoading: false,
				loading: false,
				loading2: false,
				elementLoadingText: '正在加载...',
				page: 1,
				size: 10,
				total: 0,
				options: [{
						value: 'all',
						label: '全部'
					},
					{
						value: 'jd',
						label: '京东'
					},
					{
						value: 'atb',
						label: '淘宝'
					},
					{
						value: 'sn',
						label: '苏宁'
					},
					{
						value: 'wph',
						label: '唯品会'
					},
					{
						value: 'amazon',
						label: '亚马逊'
					},
					{
						value: 'gm',
						label: '国美'
					},
				],
				form: {
					id: '',
					sqr: '',
					pinpai: '',
					zhandian: 'all',
					beginDate: '',
					endDate: ''
				},
				title2: '',
				list2: [],
				page2: 1,
				size2: 10,
				total2: 0,
				isShow2: false,
				form2: {
					shop: '',
					goods: [{
						goods: '',
						price: '',
						remark: ''
					}],
				},
				curRow: {}
			}
		},
		components: {
			Else
		},
		async created() {
			this.getList()
		},
		methods: {
			getZhanDian(en) {
				let ret = this.options.filter(item => item.value == en)
				return ret[0].label
			},
			removeDomain(item) {
				var index = this.form2.goods.indexOf(item)
				if (index !== -1) {
					this.form2.goods.splice(index, 1)
				}
			},
			addDomain() {
				this.form2.goods.push({
					goods: '',
					price: '',
					remark: ''
				});
			},
			async addHandle2() {
				let data = this.form2.goods.map(item => {
					item.shop = this.form2.shop
					item.userid = this.curRow.id
					item.id = item.id || ''
					return item
				})
				console.log(data)
				this.$refs.form2.validate(async valid => {
					if (valid) {
						this.fullLoading = true
						let res = await addOrUpdateDetail(data)
						console.log(res)
						this.fullLoading = false
						this.$message({
						  message: res.data,
						  type: 'success'
						});
						this.isShow2 = false
						this.getList2()
					}
				})
			},
			rowClisk(row) {
				let $table = this.$refs.refTable;
				this.list.map(item => {
					if (row.id != item.id) {
						$table.toggleRowExpansion(item, false)
						item.isShow = false
					}
				})
				$table.toggleRowExpansion(row)
				row.isShow = !row.isShow
				if (row.isShow) {
					this.restart2()
					this.curRow = row
					this.getList2()
				}
			},
			handleDelete2(row, index) {
				this.$confirm('此操作将删除此设置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let data = {
						id: row.id
					}
					let res = await deleteDetail(data)
					console.log(res)
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getList2()
				});
			},
			async getList2() {
				let data = {
					"userid": this.curRow.id,
					"page": this.page2,
					"size": this.size2
				}
				this.loading2 = true
				let res = await showDetail(data)
				this.loading2 = false
				console.log(res)
				this.list2 = res.data.data
				this.total2 = res.data.count
			},
			async getList() {
				let data = {
					"user": this.$store.getters.UserId,
					"corpId": this.$store.getters.CorpId,
					"dlr": this.$route.query.company,
					"page": this.page,
					"size": this.size
				}
				console.log(JSON.stringify(data))
				this.loading = true
				let res = await getWatchList(data)
				this.loading = false
				console.log(res)
				this.list = res.data.data.map(item => {
					item.isShow = false
					return item
				})
				this.total = res.data.count
			},
			async addHandle() {
				let data = {
					"user": this.$store.getters.UserId,
					"corpId": this.$store.getters.CorpId,
					"dlr": this.$route.query.company
				}
				data = Object.assign(data, this.form)
				console.log(data)
				if (data.beginDate == '') return this.$message.error('请填写开始时间');
				if (data.endDate == '') return this.$message.error('请填写结束时间');
				// data.beginDate = data.beginDate.slice(0, 10)
				// data.endDate = data.endDate.slice(0, 10)
				this.fullLoading = true
				let res = await addOrUpdate(data)
				this.fullLoading = false
				console.log(res)
				this.$message({
				  message: res.data,
				  type: 'success'
				});
				this.isShow = false
				this.getList()
			},
			handleEdit(row) {
				this.form = JSON.parse(JSON.stringify(row))
				if (this.form.beginDate == "None") this.form.beginDate = ''
				if (this.form.endDate == "None") this.form.endDate = ''
				this.isShow = true
			},
			handleDelete(row, index) {
				this.$confirm('此操作将删除此设置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let data = {
						id: row.id
					}
					let res = await watchDelete(data)
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getList()
				});
			},
			// 表格页面变化
			handleCurrentChange2(val) {
				this.page2 = val
				this.getList2()
			},
			// 表格每页显示条数变化
			handleSizeChange2(val) {
				this.restart2()
				this.size2 = val
				this.getList2()
			},
			restart2() {
				this.page2 = 1
				this.list2 = new Array()
				this.total2 = 0
			},
			// 表格页面变化
			handleCurrentChange(val) {
				this.page = val
				this.getList()
			},
			// 表格每页显示条数变化
			handleSizeChange(val) {
				this.restart()
				this.size = val
				this.getList()
			},
			restart() {
				this.page = 1
				this.list = new Array()
				this.total = 0
			},
			beginChange(val) {
				this.form.beginDate = _.formatTime(new Date(val)).slice(0, 10)
			},
			endChange(val) {
				this.form.endDate = _.formatTime(new Date(val)).slice(0, 10)
			},
			quit() {
				this.$router.go(-1)
			}
		},
		mounted() {

		}
	}
</script>

<style scoped>
	#comfire {
		/* padding-bottom: 30px; */
		height: 100vh;
	}

	.comfire-box {
		width: 1015px;
		margin: 0 auto;
		padding-top: 48px;
	}

	.comfire-title {
		height: 28px;
		border-bottom: 1px solid #D4D4D4;
		font-size: 20px;
		margin-bottom: 40px;
	}

	.comfire-middle {
		width: 1015px;
		height: 692px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(85, 84, 91, 0.2700);
		border-radius: 4px;
	}

	.btns {
		padding: 20px;
		overflow: hidden;
	}
</style>
